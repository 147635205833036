// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_1193 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 450px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // for top of page gradient
  &:before {
    @include before(100%, 295px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  // for btm of page gradient
  &:after {
    @include before(100%, 295px);
    background: url(/i/design/gradient.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
    scale: -1;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 70px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 35px;

  @media only screen and (max-width: 990px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 30px;
    text-transform: capitalize;
  }

  span {
    font-size: 60px;

    @media only screen and (max-width: 600px) {
      font-size: 30px;
    }
  }
}