// background colours
$pop1: red;
$pop2: green;


.body_1195 {
    background: url(/i/design/quick-links-bg.jpg);

    .Content1Column,
    .Content2Column,
    .Content3Column {
        background: none !important;
    }

    h1 {
        color: #B63754;
        text-align: center;

        span {
            font-weight: 700;
        }
    }
}

// background overlay
#modal-wrap {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(#000, .6);
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    z-index: 99999;
    display: none;
}

// show with JS
.blackout {
    display: block !important;
}

#curriculum-sec {
    padding: 50px 0;
    @extend .clearfix;
    position: relative;
    // background: url(/i/design/quick-links-bg.jpg);

    @media only screen and (max-width: 990px) {

    }

    @media only screen and (max-width: 1919px) {

        &:before {
            @include before(800px, 723px);
            background: url(/i/design/cutout-curriculum-simple.png) no-repeat center;
            right: 80px;
            bottom: -20px;
            background-size: contain;

            @media only screen and (max-width: 1600px) {
                height: 600px;
            }

            @media only screen and (max-width: 1350px) {
                display: none;
            }
        }
    }

    .curr-tit {
        color: #fff;
        margin: 100px 0 0 5%;
        float: left;
        text-align: left;
        line-height: .9;
        font-size: 55px;
        font-weight: 400;

        @media only screen and (max-width: 1800px) {
            font-size: 50px;
        }

        @media only screen and (max-width: 1600px) {
            font-size: 40px;
        }

        @media only screen and (max-width: 1350px) {
            font-size: 35px;
            position: relative;
            text-align: center;
            margin: 0 auto 40px;
            left: auto;
            float: none;
        }
    }

    .wrapper {
        width: 100%;
        // background: url(/i/design/quick-links-bg.jpg);

        @media only screen and (max-width: 990px) {
            width: 100% !important;
        }

    }

    ul {
        position: relative;
        max-width: 100%;
        text-align: center;
        @extend .clearfix;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 18px;

        @media only screen and (max-width: 990px) {
            justify-content: center;
            flex-direction: column;
        }

        // @media only screen and (max-width: 750px) {
        //     flex-direction: column;
        // }

        // @media only screen and (min-width: 1919px) {

        //     &:before {
        //         @include before(653px, 590px);
        //         background: url(/i/design/cutout-curriculum.png) no-repeat center;
        //         right: 70px;
        //         top: -102px;
        //     }

        // }

        // 1800

        li {
            cursor: pointer;
            position: relative;
            background: none;
            padding: 0;
            display: inline-block;
            // margin: 10px;
            transition: .3s;
            flex-shrink: 0;

            // @media only screen and (max-width: 1350px) {
            //     display: inline-block;
            //     margin: 10px;
            // }

            // @media only screen and (max-width: 990px) {
            //     max-width: 250px;
            //     display: block;
            //     margin: 0 auto 20px;
            // }

            .trigger {
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: contain;
                vertical-align: baseline;
                display: block;
                margin: 0 auto;
                transition: .3s;

                min-width: 250px !important;
            }


            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
                @media only screen and (max-width: 990px) {
                    img {
                        transform: scaleX(-1);
                    }

                    p.title {
                        left: 46% !important;
                    }

                }
            }


            &:nth-of-type(10),
            &:nth-of-type(11),
            &:nth-of-type(12) {
                img {
                    transform: scaleX(-1);

                    @media only screen and (max-width: 990px) {
                        transform: scaleX(1);
                    }
                }
            }

            &:nth-of-type(13),
            &:nth-of-type(14) {
                img {
                    transform: scaleX(-1);

                    @media only screen and (max-width: 990px) {
                        transform: scaleX(1);
                    }
                }

                p.title {
                    left: 58% !important;

                    @media only screen and (max-width: 990px) {
                        left: 42% !important;
                    }
                }
            }



            * {
                transition: .4s;
            }

            // title below the icon
            p.title {
                @include center;
                left: 42%;
                font-size: 24px;
                font-weight: 600;
                margin: 0 auto;
                max-width: 90%;
                text-align: center;
                text-align: center;
                color: #fff;
                transition: .3s;
                white-space: nowrap;

                @media only screen and (max-width: 1800px) {
                    font-size: 25px;
                }

                @media only screen and (max-width: 1600px) {
                    font-size: 22px;
                }

                @media only screen and (max-width: 1350px) {
                    font-size: 20px;
                }

                @media only screen and (max-width: 990px) {
                    left: 46% !important;
                    font-size: 18px;
                }

                @media only screen and (max-width: 450px) {
                    font-size: 16px;
                }
            }

            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(10),
            &:nth-of-type(11),
            &:nth-of-type(12) {
                p.title {
                    left: 58%;


                }
            }

            &:nth-of-type(13),
            &:nth-of-type(14) {
                @media only screen and (max-width: 990px) {
                    p.title {
                        left: 46% !important;
                    }
                }
            }

            .curr-more {
                display: block;
                color: #fff;
                font-weight: bold;
                font-size: 16px;
                text-transform: uppercase;
                text-align: center;
                box-shadow: inset 0 0 0 2px #fff;
                transition: .5s;
                margin: 30px auto 0;
                text-decoration: none;
                border-radius: 5px;
                width: 250px;
                padding: 20px 10px;

                &:hover {
                    filter: brightness(130%);
                    box-shadow: inset 0 0 0 4px #fff;
                }
            }

            // &:nth-of-type(1) {
            //     .curr-pop {
            //         border: 5px solid $pop1;

            //         h2 {
            //             color: $pop1;
            //         }

            //         .curr-more {
            //             background: $pop1;
            //         }
            //     }
            // }

            // &:nth-of-type(2) {
            //     .curr-pop {
            //         border: 5px solid $pop2;

            //         h2 {
            //             color: $pop2;
            //         }

            //         .curr-more {
            //             background: $pop2;
            //         }
            //     }
            // }

            // pop up box styles
            .curr-pop {
                width: 500px;
                max-width: 90%;
                padding: 3%;
                box-sizing: border-box;
                position: fixed;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);
                cursor: default;
                outline: 1px solid rgba(#fff, 1);
                outline-offset: 10px;
                z-index: 999999;
                background: rgba(#8b2a3c, .95);

                h2 {
                    color: #fff;
                    font-size: 35px;
                    margin: 0 auto 20px;
                    font-weight: 800;
                }

                p {
                    color: #fff;
                    font-size: 18px;
                    margin: 0 auto;
                }

                .close {
                    position: absolute;
                    right: 30px;
                    top: 20px;
                    color: #fff;
                    font-weight: bold;
                    cursor: pointer;
                    padding: 5px;
                }
            }

            // jquery classes
            .hide-me {
                opacity: 0;
                visibility: hidden;
                top: 60%;
            }

            &:hover {
                img {
                    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
                    translate: 0 -3px;
                }

                p.title {
                    // letter-spacing: 1px;
                    // text-shadow: 1px 1px 3px rgba(0, 0, 0, .8);
                    // top: 45%;
                    translate: 0 -3px;
                }
            }
        }
    }
}

// #curriculum-sec { padding: 50px 0; }
// #curriculum-sec ul { display: block; clear: both; }

// #curriculum-sec ul li { margin: 0 auto 100px; display: block; width: 90%; max-width: 700px; height: auto; padding: 20px; }
// #curriculum-sec ul li * { cursor: text; }
// #curriculum-sec ul li .close { display: none; }

// #curriculum-sec ul li:hover img { opacity: 1; }
// #curriculum-sec ul li img { display: block; margin: 0 auto; position: relative; left: auto; right: auto; top: auto; transform: none; background: #222; border: 3px dotted #fff; padding: 10px; opacity: 1!important; }

// #curriculum-sec ul li p.title { opacity: 1; position: relative; top: auto; left: auto; transform: none; background: #444; border: 3px dotted #fff; padding: 10px; color: #fff; }

// #curriculum-sec ul li .curr-pop { width: 100%;  position: relative; top: auto; left: auto; transform: none; opacity: 1; visibility: visible; outline: none; padding: 50px; margin: 50px auto; }

// #curriculum-sec ul li p.title { bottom: -20px!important; }

// #curriculum-sec ul li .curr-pop h2,
// #curriculum-sec ul li .curr-pop p,
// #curriculum-sec ul li .curr-pop .curr-more { background: #666; border: 3px dotted #fff; padding: 10px; color: #fff; height: auto; line-height: auto; }