// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');

// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #3F7CC0;
$h2-color: #AD3A50;
$h3-bg-color: #F39A4C;
$h4-bg-color: #AD3A50;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Manrope', sans-serif;
	background: url(/i/design/quick-links-bg.jpg);
}

.body_1193 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_1193 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;

	@media only screen and (max-width: 1050px) {
		width: 95%;
	}

	@media only screen and (max-width: 990px) {
		width: 90% !important;
	}
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_1193 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	position: absolute;
	top: 20px;
	left: 0;

	@media only screen and (max-width: 990px) {
		left: 0;
		right: 0;
		top: 140px;
	}

	@media only screen and (max-width: 510px) {
		top: 230px;
	}

	@media only screen and (max-width: 360px) {
		top: 240px;
	}

	a {
		display: flex;
		align-items: center;
		gap: 25px;

		@media only screen and (max-width: 990px) {
			flex-direction: column;
			gap: 15px;
		}

		img#logo {}

		h2 {
			font-size: 36px;
			text-align: left;
			line-height: 28px;
			margin: 0;
			color: #fff;
			font-weight: 600;

			span {
				display: block;
				font-size: 25px;
			}
		}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 0px;
	top: 60px;
	z-index: 99;

	@media only screen and (max-width: 990px) {
		left: 0;
		right: 0;
		scale: 0.95;
	}

	li {
		display: inline-block;
		@include box(140px, 52px);
		padding: 0;
		background: none;
		margin: 0 10px;
		transition: 0.4s;

		&:hover {
			filter: brightness(110%) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
		}

		a {
			display: block;
			@include box(100%);
		}

		#parentpay-head {
			@include box(140px, 52px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/parent-pay.png) no-repeat center;
		}

		//	#twitter-head { @include box(140px, 52px); overflow: hidden; z-index: 99; background: url(/i/design/tw-ic.png) no-repeat center; } 
		//	#instagram-head { @include box(140px, 52px); overflow: hidden; z-index: 99; background: url(/i/design/in-ic.png) no-repeat center; } 
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(140px, 52px);
	overflow: hidden;
	background: url(/i/design/translate.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(140px, 52px);
	background: url(/i/design/search.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 60px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}

// home page alternate
.body_1193 #mainholder {
	padding: 0;
	margin: 0;
}

#diary-pull-out,
#news-pull-out {
	height: 300px;
	width: 369px;
	// border-radius: 5px;
	position: fixed;
	z-index: 10;
	top: calc(50% - 130px);
	transition: 0.4s;

	@media only screen and (max-width: 990px) {
		display: none;
	}

	h2 {
		font-size: 25px;
		font-weight: 600;
		color: #fff;
		display: block;
		margin: 0;
		position: absolute;
		width: 300px;
		height: 50px;
		text-align: center;
		cursor: pointer;

		img {
			display: inline-block;
		}
	}
}

#diary-pull-out {
	background: rgb(44, 70, 129);
	background: linear-gradient(42deg, rgba(44, 70, 129, 1) 0%, rgba(120, 42, 144, 1) 100%);
	left: -310px;
	border-radius: 0 5px 5px 0;

	h2 {
		transform: rotate(-90deg) translateY(-50%);
		top: 42%;
		right: -151px;

		img {
			transform: rotate(90deg);
			margin: 0px 0px 0px 8px;
		}
	}

	.content {
		padding: 20px 65px 25px 15px;
		display: flex;
		flex-direction: column;
		height: 100%;


		#SideHeadingDiary {

			ul.SideList {
				display: flex;
				flex-direction: column;
				gap: 10px;

				li {
					transition: 0.4s;

					&:hover {
						background: rgba(255, 255, 255, .1);
					}

					&:nth-of-type(1) {
						p.date {
							background: #C852A8;
						}
					}

					&:nth-of-type(2) {
						p.date {
							background: #F39A4C;
						}
					}

					&:nth-of-type(3) {
						p.date {
							background: #AC3A4F;
						}
					}

					a {
						display: flex;
						align-items: center;
						color: #fff;
						gap: 10px;

						p.date {
							font-size: 21px;
							float: none;
							text-align: left;
							line-height: 1;
							border-radius: 5px;
							padding: 15px 6px;
							font-weight: 600;


							span {
								display: inline-block !important;
							}
						}

						p.title {
							float: none;
							width: auto !important;
							font-size: 15.8px;
							line-height: 17px;
							transition: .5s;

							// Truncate
							span {
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								height: auto !important;
								text-overflow: ellipsis;
							}
						}
					}
				}
			}
		}

		.buttons {
			margin-top: auto;

			a {
				background: #fff;
				border-radius: 5px;
				color: #285991;
				font-size: 15.8px;
				padding: 10px 15px;
				font-weight: 500;
				transition: 0.4s;

				&:hover {
					background: #285991;
					color: #fff;
				}

				&:nth-of-type(1) {
					margin: 0 9px 0 0;
				}
			}
		}
	}
}

#news-pull-out {
	background: rgb(155, 45, 66);
	background: linear-gradient(42deg, rgba(155, 45, 66, 1) 0%, rgba(113, 21, 39, 1) 100%);
	right: -310px;
	border-radius: 5px 0 0 5px;

	h2 {
		transform: rotate(90deg) translateY(-50%);
		top: 42%;
		left: -153px;

		img {
			transform: rotate(90deg);
			margin: 0px 0px 0px 8px;
		}
	}

	.content {
		// padding: 20px 15px 25px 65px;
		padding: 20px 0px 25px 60px;
		display: flex;
		flex-direction: column;
		height: 100%;

		#SideHeadingNews {

			ul.SideList {
				display: flex;
				flex-direction: column;
				gap: 10px;

				li {
					transition: 0.4s;

					&:hover {
						background: rgba(255, 255, 255, .1);
					}

					&:nth-of-type(1) {
						p.date {
							background: #285991;
						}
					}

					&:nth-of-type(2) {
						p.date {
							background: #AC3A4F;
						}
					}

					&:nth-of-type(3) {
						p.date {
							background: #F39A4C;
						}
					}

					a {
						display: flex;
						align-items: center;
						color: #fff;
						gap: 10px;

						img {
							display: none;

						}

						p.date {
							font-size: 21px;
							float: none;
							text-align: left;
							line-height: 1;
							border-radius: 5px;
							padding: 15px 6px;
							font-weight: 600;

							span {
								display: inline-block !important;
							}
						}

						p.title {
							float: none;
							width: auto !important;
							font-size: 15.8px;
							line-height: 17px;
							transition: .5s;

							// Truncate
							span {
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								height: auto !important;
								text-overflow: ellipsis;
							}
						}

						p.desc {
							display: none;
						}

						.read-more {
							display: none;
						}
					}

					.read-more {
						display: none;
					}
				}
			}
		}


		.buttons {
			margin-top: auto;

			a {
				background: #fff;
				border-radius: 5px;
				color: #AD3A50;
				font-size: 15.8px;
				padding: 10px 15px;
				font-weight: 500;
				transition: 0.4s;

				&:hover {
					background: #AD3A50;
					color: #fff;
				}

				&:nth-of-type(1) {
					margin: 0 9px 0 0;
				}
			}
		}
	}
}

.news-out {
	right: 0 !important;
}

.diary-out {
	left: 0 !important;
}

// -----------------------------------//
// Quick links SEC
// -----------------------------------//

#quick-links-sec {
	padding: 80px 0;
	position: relative;
	background: url(/i/design/quick-links-bg.jpg);

	.wrapper {

		h2 {
			@include mainHeadings;
			color: #AD3A50;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			justify-content: center;

			@media only screen and (max-width: 700px) {
				flex-direction: column;
				align-items: center;
			}

			li {
				background: none;
				padding: 0;
				position: relative;
				margin: 0;
				transition: 0.4s;

				@media only screen and (max-width: 500px) {}

				&:hover {
					filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
					translate: 0 -3px;
				}

				&:nth-of-type(4),
				&:nth-of-type(5),
				&:nth-of-type(6) {
					span {
						left: 58%;
					}

					@media only screen and (max-width: 700px) {
						img {
							scale: -1;
						}

						span {
							left: 40%;
						}
					}
				}


				img {}

				span {
					position: absolute;
					color: #fff;
					z-index: 10;
					top: 50%;
					left: 40%;
					transform: translate(-50%, -50%);
					text-transform: uppercase;
					font-size: 24px;
					font-weight: 500;

				}
			}
		}
	}
}

// -----------------------------------//
// NEWS BLOG SEC
// -----------------------------------//

#news-blog-sec {
	padding: 80px 0;
	position: relative;
	background: url(/i/design/news-blog-bg.jpg) center;

	.wrapper {
		display: flex;
		justify-content: flex-start;

		@media only screen and (max-width: 1100px) {
			flex-direction: column-reverse;
		}

		h2 {
			@include mainHeadings;
			text-align: left;

		}

		.left {
			margin: 0 55px 0 0;

			@media only screen and (max-width: 1100px) {
				margin: 0;
			}

			h2 {
				@media only screen and (max-width: 1100px) {
					margin: 40px 0;
				}
			}

			#SideHeadingNews {

				ul.SideList {
					display: flex;
					gap: 15px;

					@media only screen and (max-width: 700px) {
						width: 100%;
						flex-direction: column;
						gap: 25px;
					}

					li {
						width: 50%;
						// max-width: 265px;
						// min-width: 50%;

						@media only screen and (max-width: 1100px) {
							max-width: none !important;
							width: 100%;
						}

						a {
							color: #fff;
							display: flex;
							flex-direction: column;
							gap: 15px;

							img {
								border-radius: 10px;
								max-width: 100%;
								max-height: 170px;
								object-fit: cover;
								object-position: center center;
								order: 0;
							}

							p.date {
								position: relative;
								padding: 0 0 0 30px;
								color: #F39A4C;
								font-size: 16px;
								text-transform: uppercase;
								font-weight: 600;
								text-align: left;
								order: 2;

								&:before {
									position: absolute;
									content: "";
									background: url(/i/design/calendar-ic.png);
									width: 20px;
									height: 22px;
									left: 0;
									top: 50%;
									transform: translateY(-50%);
								}

								span {
									display: inline-block;
								}
							}

							p.title {
								font-size: 24px;
								line-height: 30px;
								order: 1;

								// Truncate
								span {
									overflow: hidden;
									display: -webkit-box;
									-webkit-line-clamp: 3;
									-webkit-box-orient: vertical;
									height: auto !important;
									text-overflow: ellipsis;
								}
							}

							p.desc {
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 7;
								-webkit-box-orient: vertical;
								height: auto !important;
								text-overflow: ellipsis;
								font-size: 18px;
								line-height: 24px;
								order: 3;
							}
						}

						a.read-more {
							position: relative;
							display: block;
							border: 3px solid #fff;
							border-radius: 10px;
							text-transform: uppercase;
							padding: 15px 0;
							font-size: 14px;
							text-align: center;
							margin: 30px 0 0 0;
							transition: 0.4s;

							&:hover {
								background: #fff;
								color: #AD3A50;
								font-weight: 500;
							}
						}
					}
				}
			}
		}

		.right {
			margin-left: auto;

			@media only screen and (max-width: 1100px) {
				margin-left: 0;
			}

			.news-board {
				width: 347px;
				background: #F39A4C;
				padding: 25px 30px;
				border-radius: 10px;

				@media only screen and (max-width: 1100px) {
					width: 100%;
				}

				img {
					max-width: 100%;

					@media only screen and (max-width: 1100px) {
						margin: 0 auto;
						max-width: 400px;
					}

					@media only screen and (max-width: 540px) {
						max-width: none;
						width: 100%;
					}
				}

				h1,
				h2,
				h3,
				h4,
				h5 {
					color: #fff;
					background: none;
					text-align: center;
					font-weight: 700;
					line-height: 1;
					margin: 0 0 15px 0;
				}

				h1 {
					font-size: 50px;
				}

				h2 {
					font-size: 40px;
				}

				h3 {
					font-size: 35px;
				}

				h4 {
					font-size: 30px;
				}

				h5 {
					font-size: 26px;
				}

				p {
					color: #fff;
					font-size: 24px;
					line-height: 1.05;
					font-weight: 600;
					text-align: center;
				}

				a {
					text-decoration: underline;
					font-size: 24px;
					line-height: 1.05;
					font-weight: 600;
					text-align: center;
					color: #fff;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	border-radius: 30px;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	border-radius: 30px;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;
	border-radius: 30px;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background: rgb(44, 70, 129);
	background: linear-gradient(42deg, rgba(44, 70, 129, 1) 0%, rgba(120, 42, 144, 1) 100%);
	border-radius: 10px;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: #222;
	// padding: 50px 0;
	position: relative;

	.wrapper {
		text-align: left;
		font-size: 18px;
	}

	p,
	a[href^="mailto:"] {
		margin: 0;
		color: #fff;
	}

}

h2.footer-heading {
	padding: 0;
	margin: 0;
	color: #fff;
}

p.address {}

p.telephone {}

footer a[href^="mailto:"] {
	display: block;
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	height: 60px;
	line-height: 60px;
	background: #285991;
	overflow: hidden;
	@extend .clearfix;

	p {
		font-size: 12px;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: #fff;
	}

	#copyright,
	#credit {
		a {
			color: #fff;

			img {
				display: inline-block;
				margin: 0 0 0 5px;
			}
		}
	}

	#copyright {
		float: left;
	}

	#credit {
		float: right;
	}
}

// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;