// @include box(200px, 300px); // @include box(200px);
@mixin box ($w, $h: $w) {
	width: $w;
	height: $h;
}

//  &:before {@include before(100%, 100%); @include bg(url(/i/design/...), cover); } 
@mixin before ($w: 100%, $h: 100%, $display: block, $pos: absolute, $content: "") {
	content: $content;
	display: $display;
	position: $pos;
	@include box($w, $h);
}

// translate trick
@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin mainHeadings {
	color: #fff;
	font-size: 60px;
	font-weight: 400;
	text-align: center;
	margin: 0 0 50px 0;

	span {
		font-weight: 600;
	}

	@media only screen and (max-width: 1100px) {
		text-align: center;
		margin: 0 0 40px 0;
	}

	@media only screen and (max-width: 700px) {
		font-size: 50px;
	}
}